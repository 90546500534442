import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { FiArrowLeftCircle } from 'react-icons/fi';

import Container from 'components/container';

import styles from './splitter.module.scss';

const Splitter = () => {
  const [showActiveSection, setShowActiveSection] = useState('none');

  const cx = classNames.bind(styles);
  const activeClasses = cx({
    splitter: true,
    [styles.splitterActiveFirst]: showActiveSection === 'first',
    [styles.splitterActiveSecond]: showActiveSection === 'second',
    [styles.splitterActive]: showActiveSection !== 'none',
  });

  return (
    <Container>
      <div className={activeClasses}>
        <section className={styles.headerWrapper}>
          <button
            type="button"
            className={styles.headingFirst}
            onClick={() => setShowActiveSection('first')}
          >
            Construction
            <br />
            Management
          </button>
          <span className={styles.headingSeparator} />
          <button
            type="button"
            className={styles.headingSecond}
            onClick={() => setShowActiveSection('second')}
          >
            Project
            <br />
            Management
          </button>
        </section>

        <section>
          <div className={styles.contentFirst}>
            <h2>Construction Management</h2>
            <p>Experienced Project Team Assembly For:</p>
            <ul className={styles.splitList}>
              <li>
                Pre Construction
                <ul>
                  <li>
                    Employer requirements.
                  </li>
                  <li>
                    Tendering documentation, construction and analysis.
                  </li>
                  <li>
                    Estimating, planning &amp; Programming.
                  </li>
                </ul>
              </li>
              <li>
                Construction
                <ul>
                  <li>
                    Competent competitive supply chain.
                  </li>
                  <li>
                    Cost Control and Quantity Surveying.
                  </li>
                  <li>
                    Works packages procurement.
                  </li>
                  <li>
                    Project delivery.
                  </li>
                </ul>
              </li>
            </ul>
            <button type="button" onClick={() => setShowActiveSection('none')}>
              <FiArrowLeftCircle />
              Back
            </button>
          </div>
          <div className={styles.contentSecond}>
            <h2>Project Management</h2>
            <p>Protecting &amp; Delivering Your Investment:</p>
            <ul>
              <li>
                Forecasting &amp; Planning, Both construction activities and cash flow.
              </li>
              <li>
                Organisation and Implementation protocols at any level in the construction cycle.
              </li>
              <li>
                Project Control, it&lsquo;s at the heart of what we do.
              </li>
              <li>
                Sub contractor letting &amp; co-ordination,
                we bring a trusted and tested supply chain.
              </li>
              <li>
                Arcuate progress and financial reporting, is key to control and resolution.
              </li>
              <li>
                Defining responsibility and accountably, with out which efficiency declines.
              </li>
              <li>
                Statutory approvals, Health &amp; Safety.
              </li>
            </ul>
            <button type="button" onClick={() => setShowActiveSection('none')}>
              <FiArrowLeftCircle />
              Back
            </button>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default Splitter;
