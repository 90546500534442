import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Container from '../components/container';
import Splitter from '../components/splitter';

const IndexPage = () => (
  <Layout>
    <SEO title="Services" />
    <Container>
      <h1>Services</h1>
    </Container>
    <Splitter />
  </Layout>
);

export default IndexPage;
